.info-card-1 {
  text-align: center;
  margin-bottom: 20px;
  border-radius: 36px;
  background: #efeefc;
  display: flex;
  width: 95%;
  height: 286px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  flex-shrink: 0;
  margin: 5px;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    /* height: 0;
    padding: 0;
    margin: 0; */
  }
}

.list-item-remove {
  animation: fadeOut 1s forwards;
}
