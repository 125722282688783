/* yes no slider css start below ;) */
.item {
  position: relative;
  width: 300px;
  height: 100px;
  pointer-events: auto;
  transform-origin: 50% 50% 0px;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  display: grid;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
  user-select: none;
}

.fg {
  cursor: -webkit-grab;
  background-color: #272727;
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.2);
  font-size: 3em;

  font-weight: 600;
  transition: box-shadow 0.75s;
}

.fg:active {
  cursor: -webkit-grabbing;
  box-shadow: 0px 15px 30px -5px rgba(0, 0, 0, 0.4);
}

.fg > * {
  pointer-events: none;
}

.av {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
}
