/* LoadingScreen.css */

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-color: #f2f2f2; */
}

.loading-text {
  font-size: 48px;
  font-weight: bold;
  animation: spin 2s linear infinite, changeText 2s steps(1) infinite;
}
@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
@keyframes rotateText {
  from {
    transform: translateY(0) rotate(0);
  }
  to {
    transform: translateY(0%) rotate(360deg);
  }
}
@keyframes changeText {
  0%,
  100% {
    content: "Wk";
  }
  50% {
    content: "?";
  }
}
