.container {
  background: #f0f0f0;
  cursor: url("https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png")
      39 39,
    auto;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.content {
  position: relative;
  width: 100%;
  height: 65px;
}

.content > div {
  position: absolute;
  width: 100%;
  height: 55px;
  transform-origin: 50% 50% 0px;
  border-radius: 5px;
  color: white;
  line-height: 40px;
  padding-left: 32px;
  /* font-size: 14.5px; */
  font-size: 20px;
  font-weight: bold;
  background: lightblue;
  /* text-transform: uppercase; */
  /* letter-spacing: 2px; */
  touch-action: none;
}
/* 
.content > div:nth-child(1) {
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
}
.content > div:nth-child(2) {
  background: linear-gradient(135deg, #f093fb 0%, #f5576c 100%);
}
.content > div:nth-child(3) {
  background: linear-gradient(135deg, #5ee7df 0%, #b490ca 100%);
}
.content > div:nth-child(4) {
  background: linear-gradient(135deg, #e27fef 0%, #f8da55 100%);
}
.content > div:nth-child(5) {
  background: linear-gradient(135deg, #4c8ef8 0%, #7a3af8 100%);
} */
.content > div {
  display: block;
  padding: 5px;
  border: 2px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  /* make the text inside unselectable: */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Navbar: */

/* Main navigation container */
.navbar {
  background-color: #fff; /* Background color */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  z-index: 1000; /* Ensure it is above other content */
}

/* Navigation items */
.navbar-item {
  text-align: center;
}

/* Icon styles */
.navbar-item i {
  font-size: 24px;
  color: #999; /* Icon color */
}

/* Active item styles */
.active i {
  color: #3897f0; /* Active icon color */
}

/* Label text styles */
.navbar-item span {
  display: block;
  font-size: 12px;
  color: #999; /* Text color */
}
