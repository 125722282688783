/* LandingPage.css */
.landing-page {
  font-family: Arial, sans-serif;
  color: #4b4453;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fefefe;
}

.card {
  background-color: #fefefe;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  animation: fadeInUp 1s ease-in-out;
}

img {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

header {
  background-color: transparent;
  padding: 10px 0;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}
/* 
nav ul li {
  margin-right: 20px;
} */

nav a {
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.3s;
}

nav a:hover {
  opacity: 0.8;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
