/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

body {
  font-size: 20px;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  --explosion-duration: 0.2s;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: transparent #fff;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* animation: explode var(--explosion-duration); */
}

.question-section,
.slider-section {
  text-align: center;
  margin-bottom: 20px;
  border-radius: 36px;
  /* background: #54c6eb; */
  background: #efeefc;

  /* animation: explode 0.35s; */
  overflow: scroll;
  display: flex;
  width: 95%;
  height: 200px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  flex-shrink: 0;
}

.question {
  font-size: 24px;
  font-weight: bold;
  color: var(--primary-color);
  /* background-color: #8a4baf; */
  padding: 10px;

  /* border-radius: 10px; */
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
  /* transform: translate(0, -5px); */
}
.answer-section {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.answer-box {
  display: block;
  padding: 15px;
  border: 2px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.3s;
}

.answer-box:hover {
  background-color: #ddd;
}

.answer {
  /* font-size: 18px; */
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  /* font-weight: 400; */
  line-height: normal;
}

@keyframes explode {
  0% {
    opacity: 0;
    transform: scale(10);
  }
  60% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes drop-zero {
  0% {
    opacity: 0;
    transform: translateY(20px);
    height: 10px;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(-2px);
    height: 0px;
    opacity: 0;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fade-out-fall {
  position: relative;
  opacity: 1;
  animation: fadeOutBottom 0.45s forwards;
}
.fallInAnimation {
  animation: fallIn 0.4s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
  /* cubic-bezier(0.42, 0.61, 0.36, 1.48); */
}
/* below animation will delay 0.5 before it start */
.fallInwithBounceAnimation {
  opacity: 0;
  animation: fallInWithLittleBounce 0.4s ease-out;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}
.fallInwithBounceAnimation1 {
  opacity: 0;
  animation: fallInWithLittleBounce 0.3s ease-out;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
}
.fallInwithBounceAnimation2 {
  opacity: 0;
  animation: fallInWithLittleBounce 0.3s ease-out;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}
@keyframes fallInWithLittleBounce {
  0% {
    opacity: 0;
    transform: translateX(0px) scale(2);
  }
  70% {
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}
@keyframes fallIn {
  0% {
    opacity: 0;
    scale: 2;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
.animate-fade-in-top {
  transform: translateY(0);
  position: relative;
  animation-name: fadeOutBottom, fadeInTop;
  animation-duration: 0.45s, 0.45s;
  animation-delay: 0s, 0.45s;
}

@keyframes fadeOutBottom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100vh);
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-100vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0vh);
  }
}
