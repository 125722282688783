/* create a css class named shrinkAndDisappear that make div shrink and disapear within 700ms so items blow it take it's place: */
.shrinkAndDisappear {
  animation: shrinkAndDisappear 1500ms ease-in;
  height: 0; /* Set the height to 0 */
  position: absolute;
}

/* create a keyframe named shrinkAndDisappear that make div shrink and disapear within 700ms so items blow it take it's place: */
@keyframes shrinkAndDisappear {
  0% {
    transform: scale(1);
    opacity: 1;
    height: auto;
  }
  /* 90% {
    transform: scale(0.6);
    transform: scale(0) translateY(-999);
    height: 10%;
  } */
  100% {
    transform: scale(0) translateY(-999);
    opacity: 0;
    height: 1px;
  }
}

/* create animation to display a div, make first rotated at 90deg y access and then 0 to make it visable  */
@keyframes fadeInFromRotation {
  0% {
    /* transform: rotateY(90deg); */
    opacity: 0;
    display: none;
  }
  100% {
    /* transform: rotateY(0); */
    opacity: 1;
    display: initial;
  }
}
@keyframes fadeOutFromRotation {
  0% {
    /* transform: rotateY(0); */
    opacity: 1;
    display: initial;
  }
  100% {
    /* transform: rotateY(90deg); */
    opacity: 0;
    display: none;
  }
}

.fadeInFromRotation {
  animation: fadeInFromRotation 0.1s ease-in;
  animation-delay: 0.3s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.fadeOutFromRotation {
  position: absolute;
  top: 100px;
  animation: fadeOutFromRotation 0.25s ease-in;
  animation-fill-mode: forwards;
}
